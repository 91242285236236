<template>
  <div class="mobileInfrared">
    <div class="imgBox">
      <img src="../../assets/mobile/product/infrared.png" class="img" />
    </div>
    <div class="param">
      <div class="title">红外人体感应探头</div>
      <div class="tit">相关参数</div>
      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">LSP01A</div>
      </div>
      <div class="text">
        <div class="left">产品尺寸:</div>
        <div class="right">Φ:94mm；开孔Φ:76mm</div>
      </div>
      <div class="text">
        <div class="left">额定输入:</div>
        <div class="right">AC200V~250V/50Hz</div>
      </div>
      <div class="text">
        <div class="left">探测角度:</div>
        <div class="right">120°</div>
      </div>
      <div class="text">
        <div class="left">探测距离:</div>
        <div class="right">＞5m（室内）</div>
      </div>
      <div class="text">
        <div class="left">传感器类型:</div>
        <div class="right">高灵敏四元数字式热释电传感器</div>
      </div>
          <div class="text">
        <div class="left">红外遥控角度（可选）:</div>
        <div class="right">110°</div>
      </div>
      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">0°C-40°C</div>
      </div>
      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>
      <div class="text">
        <div class="left">通信协议:</div>
        <div class="right">自定义LSSN2.0</div>
      </div>
      <div class="text">
        <div class="left">工作频段:</div>
        <div class="right">433.0MHz~453.0MHz</div>
      </div>
       <div class="text">
        <div class="left">待机功耗:</div>
        <div class="right">＜1W</div>
      </div>
      <div class="text">
        <div class="left">无线发射功率:</div>
        <div class="right">＜10dBm</div>
      </div>
    </div>
    <div class="paramDe">
      <div class="txt">红外模块是人体红外检测和红外控制发码功能的无线设备。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {}
};
</script>
<style scoped lang="less">
.mobileInfrared {
  background-color: #fff;
  .imgBox {
    width: 100%;
    padding-top: 0.65rem;
    padding-bottom: 0.8rem;
    display: flex;
    justify-content: center;
    .img {
      width: 3.08rem;
      height: 2.96rem;
    }
  }
  .param {
    text-align: left;
    margin: 0 0.3rem;
    padding-bottom: 0.29rem;
    border-bottom: 1px solid #333;
    .title {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #333;
    }
    .tit {
      margin-top: 0.17rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.6rem;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0;
      }
      .left {
        width: 2.5rem;
        text-align: right;
       overflow: hidden; 
        text-overflow: ellipsis; 
        -o-text-overflow: ellipsis;
        white-space:nowrap;
      }
      .right {
        margin-left: 0.59rem;
        overflow: hidden; 
        text-overflow: ellipsis; 
        -o-text-overflow: ellipsis;
        white-space:nowrap;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;

      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>